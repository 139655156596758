<template>
  <div ref="parent" class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="page d-flex flex-row flex-column-fluid">
      <!--begin::Wrapper-->
      <div class="d-flex flex-column flex-row-fluid wrapper">
        <px-header />
        <router-view />
        <px-footer />
      </div>
    </div>
  </div>
</template>

<script>
import PxHeader from '@/layouts/sections/PxHeader.vue';
import PxFooter from '@/layouts/sections/PxFooter.vue';

export default {
  name: 'PxMainLayout',
  components: {
    PxHeader,
    PxFooter,
  },
  mounted() {
    // console.log('JWT', localStorage.getItem('access_token'));
    document.body.classList.add('header-fixed', 'header-tablet-and-mobile-fixed', 'toolbar-enabled');
  },
  destroyed() {
    document.body.classList.remove('header-fixed', 'header-tablet-and-mobile-fixed', 'toolbar-enabled');
  },
};
</script>

<style lang="scss">
#kt_body {
  background-image: url('/assets/media/header-bg.png');
}
</style>
