<template>
  <div class="w-100">
    <!--begin::Menu-->
    <div
      id="#kt_header_menu"
      class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 my-5 my-lg-0 align-items-stretch fw-medium"
      data-kt-menu="true"
    >
      <router-link :to="{ name: menu.pathName }" class="menu-item me-lg-1" v-for="(menu, index) in menus" :key="index">
        <span class="menu-link py-3">
          <span class="menu-title">{{ menu.title }}</span>
        </span>
      </router-link>
    </div>
    <!--end::Menu-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        {
          pathName: 'proposals',
          title: 'Proposals',
        },
      ],
    };
  },
  mounted() {
    if (this.hasExtendedRoles) {
      this.menus = [
        {
          pathName: 'dashboard',
          title: 'Dashboard',
        },
        ...this.menus,
        {
          pathName: 'projects',
          title: 'Projects',
        },
        {
          pathName: 'map',
          title: 'Map',
        },
        {
          pathName: 'planning',
          title: 'Planning',
        },
      ];
    }
  },
};
</script>
