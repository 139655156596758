<template>
  <!--begin::Header-->
  <px-sticky>
    <!--begin::Container-->
    <div :class="['d-flex align-items-center', { 'container-xxl': !isFullWidth }, { 'container-fluid': isFullWidth }]">
      <!--begin::Header menu toggle-->
      <div class="d-flex topbar align-items-center d-lg-none ms-n2 me-3" title="Show aside menu">
        <div
          class="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
          @click="toggleMobileMenu"
        >
          <span class="svg-icon svg-icon-1">
            <inline-svg src="/assets/media/icons/duotune/abstract/abs015.svg" />
          </span>
          <!--end::Svg Icon-->
        </div>
      </div>
      <!--end::Header menu toggle-->
      <!--begin::Header Logo-->
      <div class="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
        <router-link :to="{ name: 'dashboard' }">
          <img class="logo-default h-45px" src="/assets/media/rsg-emblem.png" alt="Royal Stone Group Logo" />
          <img class="logo-sticky h-45px" src="/assets/media/rsg-emblem-dark.png" alt="Royal Stone Group Logo" />
        </router-link>
      </div>
      <!--end::Header Logo-->
      <!--begin::Wrapper-->
      <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
        <!--begin::Navbar-->
        <div class="d-flex align-items-stretch">
          <!--begin::Menu wrapper-->
          <px-drawer
            :class="[
              'header-menu align-items-stretch',
              { 'drawer drawer-start': isMobileMenuOpened },
              { 'drawer-on': isMobileMenuAnimated },
            ]"
            data-kt-drawer="true"
            data-kt-drawer-name="header-menu"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction="start"
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
          >
            <px-header-menu />
          </px-drawer>
          <!--end::Menu wrapper-->
        </div>
        <!--end::Navbar-->

        <!--begin::Toolbar wrapper-->
        <div class="topbar d-flex align-items-stretch flex-shrink-0">
          <!--begin::User-->
          <div class="d-flex align-items-center ms-3">
            <px-dropdown-content :is-default-btn-hidden="true">
              <div
                slot="btn"
                class="btn btn-icon btn-active-light-primary btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px me-2"
              >
                <span
                  v-if="currentUser && currentUser.attributes"
                  class="symbol symbol-30px symbol-md-40px"
                  v-tooltip="{
                    content: `${currentUser.attributes.first_name} ${currentUser.attributes.last_name}`,
                    delay: { show: 400, hide: 0 },
                  }"
                >
                  <img
                    v-if="currentUser.attributes.avatar"
                    :src="currentUser.attributes.avatar"
                    :alt="`${currentUser.attributes.first_name} ${currentUser.attributes.last_name}`"
                  />
                  <span v-else class="symbol-label bg-light-primary text-primary fw-semi-bold">
                    {{ currentUser.attributes.first_name.charAt(0) }}{{ currentUser.attributes.last_name.charAt(0) }}
                  </span>
                </span>
              </div>
              <div slot="content" class="text-start">
                <div class="menu-item px-1">
                  <px-btn
                    extended-classes="w-100 fs-7 px-3 py-2"
                    :in-process="signOutInProcess"
                    :disabled="signOutInProcess"
                    @click.native="signOut"
                  >
                    Sign Out
                  </px-btn>
                </div>
              </div>
            </px-dropdown-content>
          </div>
          <!--end::User -->

          <!--          <div v-if="hasExtendedRoles" class="d-flex align-items-center ms-3">-->
          <div class="d-flex align-items-center ms-3">
            <router-link
              class="btn btn-sm btn-primary btn-flex"
              :to="{ name: 'create-proposal', params: { proposalType: 'Proposal' } }"
            >
              <span class="svg-icon svg-icon-5">
                <inline-svg src="/assets/media/icons/duotune/arrows/arr075.svg" />
              </span>
              Create
            </router-link>
          </div>
        </div>
        <!--end::Toolbar wrapper-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Container-->

    <div v-if="isMobileMenuOpened" class="drawer-overlay" style="z-index: 95" @click="closeMobileMenu" />
  </px-sticky>
  <!--end::Header-->
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PxHeaderMenu from '@/layouts/sections/PxHeaderMenu';

export default {
  components: {
    PxHeaderMenu,
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/user',
      message: 'auth/message',
      isAuthenticated: 'auth/isAuthenticated',
    }),
  },
  data() {
    return {
      isMobileMenuOpened: false,
      isMobileMenuAnimated: false,
      isFullWidth: false,

      signOutInProcess: false,
    };
  },
  watch: {
    $route(to) {
      this.isFullWidth = to.name === 'map' || to.name === 'planning';
    },
  },
  mounted() {
    this.isFullWidth = this.$route.name === 'map' || this.$route.name === 'planning';
  },
  created() {
    window.addEventListener('resize', this.checkWindowSize);
  },
  destroyed() {
    window.removeEventListener('resize', this.checkWindowSize);
  },
  methods: {
    ...mapActions({
      logoutUser: 'auth/logoutUser',
    }),
    async signOut() {
      this.signOutInProcess = true;

      const response = await this.$gAuth.signOut();

      if (response) {
        await this.logoutUser();
        await this.$router.push({ name: 'login' });
      }

      this.signOutInProcess = false;
    },
    toggleMobileMenu() {
      this.isMobileMenuOpened = !this.isMobileMenuOpened;

      setTimeout(() => (this.isMobileMenuAnimated = !this.isMobileMenuAnimated));
    },
    closeMobileMenu() {
      this.isMobileMenuAnimated = false;

      setTimeout(() => {
        this.isMobileMenuOpened = false;
      }, 300);
    },
    checkWindowSize() {
      if (window.innerWidth >= 992) {
        this.closeMobileMenu();
      }
    },
  },
};
</script>
