<template>
  <div>
    <div
      :class="['footer small py-6 border-top', { 'container-xxl': !isFullWidth }, { 'container-fluid': isFullWidth }]"
    >
      <div class="row justify-content-between align-items-center">
        <div class="col">
          <p class="font-size-sm mb-0">&copy; {{ Date.now() | moment('YYYY') }} Royal Stone Group</p>
        </div>
        <div class="col-auto" />
      </div>
    </div>
    <px-scroll-top />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFullWidth: false,
    };
  },
  watch: {
    $route(to) {
      this.isFullWidth = to.name === 'map';
    },
  },
  mounted() {
    this.isFullWidth = this.$route.name === 'map';
  },
};
</script>
